import React, { useState, useEffect, useRef } from 'react';
import Layout from '../layouts/index';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import translationsEn from '../locales/en.json';
import translationsEs from '../locales/es.json';
import { format, parseISO } from 'date-fns';
import { es, enUS } from 'date-fns/locale';

const Product = ({ data }) => {
  const product = data.datoCmsProduct;
  const [loupePosition, setLoupePosition] = useState({ x: 0, y: 0 });
  const [showLoupe, setShowLoupe] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [locale, setLocale] = useState('es');

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLocale = localStorage.getItem('locale') || 'es';
      setLocale(storedLocale);
    }

    const handleLanguageChange = (event) => {
      setLocale(event.detail);
    };

    if (typeof window !== "undefined") {
      window.addEventListener('languageChanged', handleLanguageChange);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener('languageChanged', handleLanguageChange);
      }
    };
  }, []);

  useEffect(() => {
    // Llamada a la API para obtener las valoraciones del producto
    fetch(`https://api.osdocasal.com:8090/getProductReviewBySku/${product.sku}`)
      .then(response => response.json())
      .then(data => {
        setReviews(data);

        // Calcular la media de las valoraciones
        const totalRating = data.reduce((acc, review) => acc + review.rating, 0);
        const avgRating = totalRating / data.length;
        setAverageRating(Math.ceil(avgRating)); // Redondear al alza
      })
      .catch(error => console.error('Error fetching product reviews:', error));
  }, [product.sku]);

  const translations = locale === 'es' ? translationsEs : translationsEn;

  const SlickButtonFix = ({currentSlide, slideCount, children, ...props}) => (
    <span {...props}>{children}</span>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <SlickButtonFix>
        <ArrowBackIosIcon />
    </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
          <ArrowForwardIosIcon />
      </SlickButtonFix>
    ),
  };

  const handleMouseEnter = () => {
    setShowLoupe(true);
  };

  const handleMouseMove = (e) => {
    const { left, top } = e.target.getBoundingClientRect();
    const { clientX, clientY } = e;

    // Calculamos la posición de la lupa respecto a la imagen
    const x = (clientX - left);
    const y = (clientY - top);

    // Ajustamos la posición de la lupa para que el cursor esté centrado
    const backgroundPositionX = -x * 8 - 400; // 75 es la mitad del tamaño de la lupa
    const backgroundPositionY = -y * 8 - 400; // 75 es la mitad del tamaño de la lupa

    setLoupePosition({ x, y, backgroundPositionX, backgroundPositionY });
  };

  const handleTouchMove = (e) => {
    const { left, top } = e.target.getBoundingClientRect();
    const { touches } = e;

    if (touches.length > 0) {
      const { clientX, clientY } = touches[0];

      const x = (clientX - left);
      const y = (clientY - top);

      const backgroundPositionX = -x * 8 - 400;
      const backgroundPositionY = -y * 8 - 400;

      setLoupePosition({ x, y, backgroundPositionX, backgroundPositionY });
    }
  };

  const handleMouseLeave = () => {
    setShowLoupe(false);
  };

  const sliderRef = useRef(null); 

  const getLocale = (locale) => {
    switch (locale) {
      case 'es':
        return es;
      case 'en':
      default:
        return enUS;
    }
  };  

  return (
    <Layout
      site={data.site}
      seo={{ ...data.site.globalSeo, ...data.site.faviconMetaTags }}
      lang={locale}
      title={product.name + " - OS DO CASAL"}
      description={product.name}
    >
      <div className="product-display__item">
        <Slider {...settings} ref={sliderRef}>
          {product.imagegalery.map((image, index) => (
            <div
              key={index}
              className={`carousel-image ${sliderRef.current && sliderRef.current.innerSlider.state.currentSlide === index ? 'active' : ''}`}
              onMouseEnter={handleMouseEnter}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              onTouchMove={handleTouchMove}
            >
              <Img fluid={image.fluid} loading="lazy" />
              {showLoupe && (
                <div
                  className="loupe"
                  style={{
                    backgroundImage: `url(${image.fluid.src})`,
                    backgroundPosition: `-${loupePosition.x}px -${loupePosition.y}px`,
                    left: loupePosition.x,
                    top: loupePosition.y,
                  }}
                />
              )}
            </div>
          ))}
        </Slider>
        <div
          className="Product snipcart-add-item"
          data-item-id={product.id}
          data-item-price={product.price}
          data-item-image={product.image.url}
          data-item-name={product.name}
          data-item-url={`/${locale}/product/${product.seourl}`}
          data-item-custom1-name="SKU"
          data-item-custom1-value={product.sku}
        >
          {reviews.length > 0 && (
            <div className="product-ratings">
              <div className="average-rating">
                {[...Array(averageRating)].map((star, index) => (
                  <span className="product-rating" key={index}>★</span>
                ))}
              </div>
            </div>
          )}
          <div className="Product__details">
            <div className="Product__name">
              {product.name}
              <div className="Product__price">{product.price}€</div>
            </div>
            <span className="Product__buy">{translations['buy']}</span>
          </div>
        </div>
        {product.xsell.length > 0 && (
          <div className="recommendations">
            <h2>{translations['u-may-also-like']}</h2>
          </div>
        )}
        <div className="thumbnails">
          {product.xsell.map((xProduct, index) => (
            <div className="thumbnail">
              <Link to={`/${xProduct.locale}/product/${xProduct.seourl}`} aria-label={xProduct.name}>
                <Img fluid={xProduct.image.fluid} loading="lazy" className="thumbnail-image" />
              </Link>
            </div>
          ))}
        </div>
        <div className="product-reviews">
          {reviews.length > 0 ? (
            <>
              <h3>{translations['product-reviews']}</h3>
              <div className="reviews-list">
                {reviews.map((review, index) => (
                  <div key={index} className="review">
                    <div className="customer-rating">
                      {[...Array(review.rating)].map((star, index) => (
                        <span className="customer-rating__product" key={index}>★</span>
                      ))}
                      <span className="customer-rating__created-at" key={index}>
                        {review.userName} - {format(parseISO(review.createdAt), "PPP", { locale: getLocale(locale) })}
                      </span>
                    </div>
                    <div className="customer-rating__comment">{review.comment}</div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div>{translations['no-reviews']}</div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($productId: String, $locale: String) {
    datoCmsProduct(id: { eq: $productId }, locale: { eq: $locale }) {
      id
      sku
      seourl
      name
      price
      locale
      image {
        url
        fluid(maxWidth: 300, imgixParams: { fm: "webp" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      imagegalery {
        fluid(maxWidth: 500, imgixParams: { fm: "webp" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      xsell {
        id
        locale
        name
        seourl
        price
        image {
          fluid(maxWidth: 300, imgixParams: { fm: "webp" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    site: datoCmsSite {
      faviconMetaTags {
        tags
      }
      globalSeo {
        siteName
      }
    }
  }
`;

export default Product;